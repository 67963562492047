
export const dashboardPath = "/dashboard";
export const teamsPath = dashboardPath + "/teams"
export const matchPath = dashboardPath + "/matches"
export const combinationPath = dashboardPath + "/combinations"
export const categoryPath = "/category"
export const usersPath = dashboardPath + "/users";
export const channelPath = dashboardPath + "/channels"
export const alternateLinksPath = dashboardPath + "/alternateLinks"
export const editUserPath = "/users/edit"
export const manageAccountPath = dashboardPath + "/manageAccount"
export const sportsPath = "/sports/"
export const loginPath = "/login/";
