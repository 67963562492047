import {
  Avatar,
  Backdrop,
  Box,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import PageHeader from "../../components/header";
import TrashIcon from "../../assets/svg/trash.svg";
import EditIcon from "../../assets/svg/edit.svg";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../components/button";
import AppInput from "../../components/newInput";
import { useEffect, useState } from "react";
import API_CALL from "../../services";
import notify from "../../utils/notify";

import { getSportFromSession } from "../../utils/utils";
import defaultChannels from "./defaultChannels";
import IconButton from "../../components/iconButton";

interface Link {
  label: string;
  name?: string;
  link?: string;
}

function Channels() {
  const emptyChannel = { title: "", icon: "", value: "" };
  const [channels, setChannels] = useState([emptyChannel]);
  const [currentChannel, setCurrentChannel] = useState<string>("");
  const [open, setOpen] = useState(false);
  const defaultLinks = [
    { label: "Link 1", name: undefined, link: undefined },
    { label: "Link 2", name: undefined, link: undefined },
  ];

  const [links, setLinks] = useState<Link[]>(defaultLinks);

  function onChange(
    idx: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setChannels((prev) => {
      const arr = [...prev];
      const el = e.target.name as keyof (typeof channels)[0];
      arr[idx][el] = e.target.value;
      if (el === "title") {
        const currentChannel = defaultChannels.find(
          (val) => val.value === e.target.value
        );
        arr[idx].title = currentChannel?.label || "";
        arr[idx].icon = currentChannel?.icon || "";
        arr[idx].value = currentChannel?.value || "";
      } else {
        arr[idx][el] = e.target.value;
      }
      return arr;
    });
  }

  function addChannel() {
    setChannels((prev) => [...prev, emptyChannel]);
  }

  function deleteChannel(idx: number) {
    setChannels((prev) => prev.filter((_, id) => id !== idx));
  }

  async function createChannel() {
    const isNotEmpty = channels.find((val) => val.title);
    if (isNotEmpty) {
      try {
        await API_CALL.createChannel({
          ...getSportFromSession(),
          alternateLinks: channels,
        });
        notify("success", "Channels Updated Successfully");
      } catch (e) {
        console.log(e);
      }
    } else {
      notify("error", "Please select atleast one channel and enter its link");
    }
  }

  const handleAddLink = () => {
    const newLabel = `Link ${links.length + 1}`;
    setLinks((prevLinks) => [
      ...prevLinks,
      { label: newLabel, name: "", link: "" },
    ]);
  };

  async function getChannels() {
    try {
      const sportArr = [
        Object.values(getSportFromSession())[0],
        Object.values(getSportFromSession())[1],
      ] as const;
      const { data: res } = await API_CALL.getChannels(...sportArr);
      if (res.data.channels?.links?.length) {
        const filtered = res.data.channels.links.map((val: any) => ({
          title: val.title,
          icon: val.icon,
          value: val.title,
          link: val.link,
        }));
        setChannels(filtered);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleClose = async () => {
    setOpen(false);
  };

  const handleInputChange = (
    index: number,
    field: keyof Link,
    value: string
  ) => {
    const updatedLinks = [...links];
    updatedLinks[index][field] = value;
    setLinks(updatedLinks);
  };

  const deleteLink = (idx: number) => {
    setLinks((prev) => prev.filter((_, id) => id !== idx));
  };

  const handleOpen = async (name: string) => {
    if (!name) {
      return notify("error", "Please select a channel first")
    }
    const slug = name.toLowerCase().split(" ").join("-");
    setCurrentChannel(slug);

    try {
      const currentSport = getSportFromSession();
      const { data: res } = await API_CALL.getChannelLinks(
        currentSport.sport,
        currentSport.league,
        slug
      );
      if (res.data.channelLinks) {
        setLinks(
          res.data.channelLinks.links.map((val: any) => ({
            label: val.title,
            link: val.link,
            name: val.title,
          }))
        );
      } else {
        setLinks(defaultLinks);
      }
      setOpen(true);
    } catch (err) {
      notify("error", "An unexpected error occured!");
    }
  };

  const addStreamingLinkAndClose = async () => {
    const data = {
      ...getSportFromSession(),
      links: links
        .filter((val) => val.name && val.link)
        .map((val) => ({
          title: val.name as string,
          link: val.link as string,
        })),
    };
    try {
      const channelLinks = data.links.filter((val) => val.title && val.link);
      const { league, sport } = data;
      await API_CALL.addChannelLinks({
        links: channelLinks,
        channel: currentChannel,
        league,
        sport,
      });
      notify("success", "Channel links added successfully");
      setOpen(false);
    } catch (error) {
      notify("error", "Error adding channel links");
    }
  };

  useEffect(() => {
    getChannels();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box component="div">
      <PageHeader title="Channels" />
      <Paper variant="outlined" sx={{ borderRadius: "16px" }}>
        <Box
          sx={{
            padding: "60px 176px", // top and bottom padding: 60px, left and right padding: 176px
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              {channels.map((val, idx) => (
                <Box
                  key={idx}
                  width="100%"
                  display={"flex"}
                  alignItems={"center"}
                  columnGap={2}
                >
                  <Typography sx={{ fontSize: "28px", fontWeight: 700 }}>
                    {String(idx + 1).padStart(2, "0")}
                  </Typography>
                  <Box
                    width={"100%"}
                    display="flex"
                    alignItems="flex-end"
                    padding={1}
                    columnGap={2}
                    sx={{ marginBottom: "30px" }}
                  >
                    <Grid item xs={12}>
                      <AppInput
                        name="title"
                        label="Channel Name"
                        selectOptions={defaultChannels}
                        value={val.value}
                        onChange={(e) => onChange(idx, e)}
                      />
                    </Grid>
                    <Grid item xs={2} display="flex" gap={2}>
                      <IconButton onClick={() => handleOpen(val.title)}>
                        <Avatar
                          src={EditIcon}
                          alt="Edit Icon"
                          sx={{
                            height: "20px",
                            width: "20px",
                            borderRadius: 0,
                          }}
                        />
                      </IconButton>
                      {channels.length > 1 && (
                        <IconButton onClick={() => deleteChannel(idx)}>
                          <Avatar
                            src={TrashIcon}
                            alt="Trash Icon"
                            sx={{
                              height: "20px",
                              width: "20px",
                              borderRadius: 0,
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "60px",
                gap: 4,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                sx={{ width: "274px" }}
                onClick={addChannel}
              >
                Add Channel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ width: "274px" }}
                onClick={createChannel}
              >
                Save
              </Button>
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          "& .css-vtbemp": {
            borderRadius: "24px",
            borderColor: "transparent",
            width: "60%",
            maxHeight: "85%",
            overflow: "auto",
            p: "32px",
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
              sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
            >
              Apply Channel Links
            </Typography>
            <Box id="transition-modal-description" sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                {links.map((link, index) => (
                  <Box key={index}>
                    <Typography>{link.label}:</Typography>
                    <Box
                      display="flex"
                      columnGap="20px"
                      alignItems="flex-end"
                      mt={1}
                    >
                      <Box width="30%">
                        <AppInput
                          label="Name"
                          placeholder="HD"
                          value={link.name}
                          onChange={(e) =>
                            handleInputChange(index, "name", e.target.value)
                          }
                        />
                      </Box>
                      <Box flex={1}>
                        <AppInput
                          label="Link"
                          placeholder="https://www.soccerstream1234.com./soccerlive/today/4785478"
                          value={link.link}
                          onChange={(e) =>
                            handleInputChange(index, "link", e.target.value)
                          }
                        />
                      </Box>
                      <IconButton onClick={() => deleteLink(index)}>
                        <Avatar
                          src={TrashIcon}
                          alt="trash Icon"
                          sx={{
                            height: "20px",
                            width: "20px",
                            borderRadius: 0,
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
                <Box>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleAddLink}
                  >
                    {links.length > 0 ? "Add more links" : "Add streaming link"}
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  columnGap: 1,
                  mt: 2,
                  pb: 2,
                  float: "right",
                }}
              >
                <Button
                  title="Add Streaming Links"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  title="Add Streaming Links"
                  variant="contained"
                  onClick={addStreamingLinkAndClose}
                >
                  Apply now
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Channels;
