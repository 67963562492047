// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_table__DbKlS {
  position: relative;
  margin-top: 20px;
}
.style_table__DbKlS table {
  width: 100%;
  border-collapse: collapse;
}
.style_table__DbKlS table th, .style_table__DbKlS table td {
  border-bottom: 0.4px solid #979797;
  padding: 8px;
}
.style_table__DbKlS table td {
  font-size: 14px !important;
  font-weight: 600;
  color: #202224;
}
.style_table__DbKlS table th {
  font-weight: 800;
  color: #202224;
}
.style_table__DbKlS .style_pagination__1w0bm {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_table__DbKlS .style_pagination__1w0bm span {
  padding: 8px 16px;
}
.style_table__DbKlS .style_pagination__controls__jdUP2 button {
  cursor: pointer;
  background-color: #f2f2f2;
  border: none;
  padding: 8px 16px;
}
.style_table__DbKlS .style_pagination__controls__jdUP2 button:hover {
  background-color: #ddd;
}

.style_tableCell__3fdD4 {
  font-size: 13px;
  font-weight: 400;
}

.style_headerClass__NSnFW {
  font-size: 15px !important;
  font-weight: 800 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/style.module.scss"],"names":[],"mappings":"AAKA;EACE,kBAAA;EACA,gBAAA;AAJF;AAME;EACE,WAAA;EACA,yBAAA;AAJJ;AAMI;EAEE,kCAAA;EACA,YAAA;AALN;AAQI;EACE,0BAAA;EACA,gBAAA;EACA,cAAA;AANN;AAUI;EAEE,gBAAA;EACA,cAAA;AATN;AAeE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAbJ;AAgBI;EACE,iBAAA;AAdN;AAkBM;EACE,eAAA;EACA,yBA/CuB;EAgDvB,YAAA;EACA,iBAAA;AAhBR;AAmBM;EACE,sBApD6B;AAmCrC;;AAsBA;EACE,eAAA;EACA,gBAAA;AAnBF;;AAqBA;EACE,0BAAA;EACA,2BAAA;AAlBF","sourcesContent":["$table-background: #f2f2f2;\n$pagination-background: #f2f2f2;\n$pagination-button-background: #f2f2f2;\n$pagination-button-hover-background: #ddd;\n\n.table {\n  position: relative;\n  margin-top: 20px;\n\n  table {\n    width: 100%;\n    border-collapse: collapse;\n\n    th, td {\n  \n      border-bottom: 0.4px solid #979797;\n      padding: 8px;\n      // text-align: left;\n    }\n    td{\n      font-size: 14px !important;\n      font-weight: 600;\n      color:#202224\n\n    }\n\n    th {\n      // background-color: $table-background;\n      font-weight: 800;\n      color:#202224\n      \n\n    }\n  }\n\n  .pagination {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    // background-color: $pagination-background;\n\n    span {\n      padding: 8px 16px;\n    }\n\n    &__controls {\n      button {\n        cursor: pointer;\n        background-color: $pagination-button-background;\n        border: none;\n        padding: 8px 16px;\n      }\n\n      button:hover {\n        background-color: $pagination-button-hover-background;\n      }\n    }\n  }\n}\n.tableCell {\n  font-size: 13px;\n  font-weight: 400;\n}\n.headerClass{\n  font-size: 15px !important;\n  font-weight: 800 !important;\n  // margin-left: 200px;`\n}\n\n// .dataGrid{\n//   border: 2px solid red;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `style_table__DbKlS`,
	"pagination": `style_pagination__1w0bm`,
	"pagination__controls": `style_pagination__controls__jdUP2`,
	"tableCell": `style_tableCell__3fdD4`,
	"headerClass": `style_headerClass__NSnFW`
};
export default ___CSS_LOADER_EXPORT___;
